function isArray(obj) {
	return obj && ( typeof obj === 'object') && (obj.constructor == Array);
}

//必填
function checkRequired(fieldId,tipMsg){ 
	var str=getValue(fieldId);
	if(str == "null" || str == null || typeof (str) == "undefined" || str == "undefined" || str == ""){
		alert("请输入"+tipMsg);
		return false;
	}
	return true;
}
function checkRequired2(fieldId,tipMsg){
	var str=getValue(fieldId);
	if ($("#"+fieldId).attr('placeholder') == $("#"+fieldId).val()) {
		str="";
	}
	
    if(str == "null" || str == null || typeof (str) == "undefined" || str == "undefined" || str == ""){
        errShow(fieldId,tipMsg);
        return false;
    }
    return true;
}
function errShow(fieldId,tipMsg){
	console.log(fieldId,tipMsg)
	$('#e_'+fieldId).removeClass('none');
    $('#e_'+fieldId).html('<i class="ic-error"></i>'+tipMsg);
}
//手机号
function checkMoblie(fieldId,tipMsg){
	var fieldValue=fieldId;
//	if(fieldValue!=""&&!(/^1[3|5|7|8][0-9]\d{4,8}$/.test(fieldValue))){
//		alert("请输入正确"+tipMsg);
//		return false;
//	}
	return true;
}
function checkMoblie2(fieldId,tipMsg){
    var fieldValue=getValue(fieldId);
    if(fieldValue == '' || fieldValue==null){
        errShow(fieldId,tipMsg);
        return false;
	}
    if(!/^1[0-9]{10}$/i.test(fieldValue)){
        errShow(fieldId,tipMsg);
        return false;
    }
    return true;
}
//2.密码输入框只能输入英文字母大小写+数字+符号(通过键盘能在英文状态下输入的字符：~!@#$%^&*()｛[}]\|`,<>./?;:'"及空格)
function checkPassword(fieldId,tipMsg){
	var fieldValue=getValue(fieldId);
	var re =new RegExp("^([A-Za-z0-9_\~\`\!\@\#\$\%\^\&\*\(\)\+\=\{\}\\[\\]\|\:\;\'\"\<\>\,\.\?\/]|[-]|[\\\\]|[ ]){0,}$","i");
	if(fieldValue!=""&&!re.test(fieldValue)){
		alert(tipMsg+"不合法");
		return false;
	}
	return true;
}
function checkPassword2(fieldId,tipMsg){
    var fieldValue=getValue(fieldId);
    var re =new RegExp("^([A-Za-z0-9_\~\`\!\@\#\$\%\^\&\*\(\)\+\=\{\}\\[\\]\|\:\;\'\"\<\>\,\.\?\/]|[-]|[\\\\]|[ ]){0,}$","i");
    if(fieldValue!=""&&!re.test(fieldValue)){
        errShow(fieldId,tipMsg);
        return false;
    }
    return true;
}

//密码强度校验
function checkPassword3(fieldId,tipMsg){
	/*
	 返回密码的强度级别
	 */
    function checkStrong(sPW) {
        if (sPW.length < 8||sPW.length > 20)
            return 0; //密码太短或太长
        Modes = 0;
        for (i = 0; i < sPW.length; i++) {
            //测试每一个字符的类别并统计一共有多少种模式.
            Modes |= CharMode(sPW.charCodeAt(i));
        }
        return bitTotal(Modes);
    }
	/*
	 判断字符类型
	 */
    function CharMode(iN) {
        if (iN >= 48 && iN <= 57) //数字
            return 1;
        if (iN >= 65 && iN <= 90) //大写字母
            return 2;
        if (iN >= 97 && iN <= 122) //小写
            return 4;
        else
            return 8; //特殊字符
    }
	/*
	 统计字符类型
	 */
    function bitTotal(num) {

        modes = 0;
        for (i = 0; i < 4; i++) {
            if (num & 1) modes++;
            num >>>= 1;
        }
        return modes;
    }
    /**
	 * 密码强度等级说明，字符包括：小写字母、大写字母、数字、特殊字符
	 * 1---密码包含其中之一
	 * 2---密码包含其中之二
	 * 3---密码包含其中之三
	 * 4---密码包含其中之四
     */
    var fieldValue=getValue(fieldId);
    if(fieldValue!=""&&checkStrong(fieldValue) < 2){
    	tipMsg='登录密码由8-20位数字+字母组成，字母区分大小写';
        errShow(fieldId,tipMsg);
        return false;
    }
    return true;
}
//4 昵称-只能输入中英文和数字，其他不能输入
function checkLoginName(fieldId,tipMsg){	
	var fieldValue=getValue(fieldId);
	if(fieldValue!=""&&!/^([a-zA-Z0-9]|[\u4e00-\u9fa5])+$/i.test(fieldValue)){
		alert(tipMsg+"不合法");
		return false;
	}
	return true;
}
function checkLoginName2(fieldId,tipMsg){
    var fieldValue=getValue(fieldId);
    if(fieldValue!=""&&!/^([a-zA-Z0-9]|[\u4e00-\u9fa5])+$/i.test(fieldValue)){
        errShow(fieldId,tipMsg);
        return false;
    }
    return true;
}
// 不允许纯空格
function checkBlank(fieldId,tipMsg){
	var fieldValue=getValue(fieldId);
	if(fieldValue!=""&&/^(( )|(　)){1,}$/i.test(fieldValue)){
		alert("请输入合法的"+tipMsg+",不允许为纯空格");
		return false;
	}
	return true;
}
function checkBlank2(fieldId,tipMsg){
    var fieldValue=getValue(fieldId);
    if(fieldValue!=""&&/^(( )|(　)){1,}$/i.test(fieldValue)){
        errShow(fieldId,tipMsg);
        return false;
    }
    return true;
}
//  不允许纯数字
function checkFullNumber(fieldId,tipMsg){ 
	var fieldValue=getValue(fieldId);
	if(fieldValue!=""&&/^[0-9]{1,}$/i.test(fieldValue)){
		alert(tipMsg+"不允许为纯数字");
		return false;
	}
	return true;
}
//不允许纯英文
function checkFullEnglish(fieldId,tipMsg){ 
	var fieldValue=getValue(fieldId);
	if(fieldValue!=""&&/^[a-zA-Z]{1,}$/i.test(fieldValue)){
		alert(tipMsg+"不允许为纯英文");
		return false;
	}
	return true;
}
function checkFullEnglish2(fieldId,tipMsg){
    var fieldValue=getValue(fieldId);
    if(fieldValue!=""&&/^[a-zA-Z]{1,}$/i.test(fieldValue)){
        errShow(fieldId,tipMsg);
        return false;
    }
    return true;
}
//  只能输入数字
function checkNumber(fieldId,tipMsg){
    var fieldValue=getValue(fieldId);
	if(fieldValue!=""&&!/^[0-9]{1,}$/i.test(fieldValue)){
		alert("请输入正确"+tipMsg);
		return false;
	}
	return true;
}
function checkNumber2(fieldId,tipMsg){
    var fieldValue=getValue(fieldId);
    if(fieldValue!=""&&!/^[0-9]{1,}$/i.test(fieldValue)){
        errShow(fieldId,tipMsg);
        return false;
    }
    return true;
}

//只能输入中文
function checkChinese(fieldId,tipMsg){ 
	var fieldValue=getValue(fieldId);
	if(fieldValue!=""&&!/^[\u4e00-\u9fa5]{1,}$/i.test(fieldValue)){
		alert(tipMsg+"只允许输入中文");
		return false;
	}
	return true;
}
function checkChinese2(fieldId,tipMsg){
    var fieldValue=getValue(fieldId);
    if(fieldValue!=""&&!/^[\u4e00-\u9fa5]{1,}$/i.test(fieldValue)){
        errShow(fieldId,tipMsg);
        return false;
    }
    return true;
}
//	交易密码
function checkTransPwd(fieldId,tipMsg){ 
	var fieldValue=getValue(fieldId);
	if(fieldValue!=""&&!/^[0-9]{6}$/i.test(fieldValue)){
		alert(tipMsg+"不合法");
		return false;
	}
	return true;
}
function checkTransPwd2(fieldId,tipMsg){
    var fieldValue=getValue(fieldId);
    if(fieldValue!=""&&!/^[0-9]{6}$/i.test(fieldValue)){
        errShow(fieldId,tipMsg);
        return false;
    }
    return true;
}
//验证码校验
function checkImgCode(fieldId,tipMsg){ 
	var fieldValue=getValue(fieldId);
	if(fieldValue!=""&&!/^[0-9a-zA-Z]{4}$/i.test(fieldValue)){
		alert(tipMsg+"必须是4位英文或数字");
		return false;
	}
	return true;
}
//验证码校验
function checkImgCode2(fieldId,tipMsg){
    var fieldValue=getValue(fieldId);
    if(fieldValue!=""&&!/^[0-9a-zA-Z]{4}$/i.test(fieldValue)){
        errShow(fieldId,tipMsg);
        return false;
    }
    return true;
}
// 长度控制
function checkLimitLen(fieldId,tipMsg,minlength,maxlength){	
	var fieldValue=getValue(fieldId);	
	var re =new RegExp("^.{"+minlength+","+maxlength+"}$","i");
	if(fieldValue!=""&&!re.test(fieldValue)){
		alert(tipMsg+"长度必须在"+minlength+"-"+maxlength+"之间");
		return false;
	}
	return true;
}
function checkLimitLen2(fieldId,tipMsg,minlength,maxlength){
    var fieldValue=getValue(fieldId);
    var re =new RegExp("^.{"+minlength+","+maxlength+"}$","i");
    if(fieldValue!=""&&!re.test(fieldValue)){
        errShow(fieldId,tipMsg+"长度必须在"+minlength+"-"+maxlength+"之间");
        return false;
    }
    return true;
}
//最小长度
function checkMinLen(fieldId,tipMsg,minlength){	
	var fieldValue=getValue(fieldId);	
	var re =new RegExp("^.{"+minlength+",}$","i");
	if(fieldValue!=""&&!re.test(fieldValue)){
        errShow(fieldId,tipMsg+"长度不能小于"+minlength+"位");
		return false;
	}
	return true;
}
// 最大长度
function checkMaxLen(fieldId,tipMsg,maxlength){	
	var fieldValue=getValue(fieldId);	
	var re =new RegExp("^.{0,"+maxlength+"}$","i");
	if(fieldValue!=""&&!re.test(fieldValue)){
        errShow(fieldId,tipMsg+"长度不能超过"+maxlength+"位");
		return false;
	}
	return true;
}
//7 身份证号码验证
function checkIDCard(fieldId,tipMsg){
	var fieldValue=getValue(fieldId);
	
	if(fieldValue==""||!/(^\d{15}$)|(^\d{18}$)|(^(\d{17})(\d|X|x)$)/i.test(fieldValue)){
        errShow(fieldId,tipMsg+"非空或格式错误");
		return false;
	}
	return true;
}

//银行卡号验证
function checkBankCard(fieldId,tipMsg){
	
	var fieldValue=$("#"+fieldId).val();
	var cardNum = $("#"+fieldId).val().replace(/\s+/g,"");
	if(cardNum==""||!/(^\d{16,19}$)$/i.test(cardNum)){
        errShow(fieldId,"银行卡号应为16~19位数字");
		return false;
	}else{
		return true;
	}
}
/*
 * 交易金额判断
 * 规则:不能输入负值和0，小数点允许两位。
 * */
function checkAmt(fieldId,tipMsg){
	var fieldValue=getValue(fieldId);
	
	var re = /^([1-9][\d]{0,12})(\.[\d]{1,2})?$/;
    var re2 = /^([0]{1}[.]{1}[1-9]{1}[0-9]{0,1})$/;
    var re3 = /^([0]{1}[.]{1}[0]{1}[1-9]{1})$/;
    if (re.test(fieldValue) || re2.test(fieldValue)  || re3.test(fieldValue)) {
    	return true;
    } else {
//    	alert(tipMsg+"不能输入负值和0，小数点允许两位");
        errShow(fieldId,"请输入有效金额");
    	return false;
    }
}

/*
 * 姓名校验
 * */
function checkName(name){
	var re = /^[0-9a-zA-Z\u4e00-\u9fa5_]{2,16}$/;
	if (re.test(name)) {
    	return true;
    } else {
    	return false;
    }
	return true;
}

/*
 * 真实姓名-银行业规则校验
 * */
function checkRealName(fieldId,tipMsg){
	var fieldValue=getValue(fieldId);
	
	var re = /^([\\·a-zA-Z\u4e00-\u9fa5]|[ ]|[　]){2,30}$/;
	var re2 = /^([\\·]|[ ]|[　]){1,}$/;
	if (re.test(fieldValue) && !re2.test(fieldValue)) {
    	return true;
    } else if(!re.test(fieldValue)){
//    	alert(tipMsg+'为2-30位汉字、英文、空格或·');
        errShow(fieldId,'请输入正确姓名');
    	return false;
    } else{
//    	alert(tipMsg+'不能为纯空格或·');
        errShow(fieldId,'请输入正确姓名');
    	return false;
    }
	return true;
}

/*
 * 登录密码校验
 * 验证规则：8-20位英文字母或数字组成。
 * */
function checkLoginPwd(str){
    // var  re = /^[a-zA-Z0-9]{8,20}$/;
    // if (re.test(str)) {
    // 	return true;
    // } else {
    // 	return false;
    // }
    if ( /^[A-Za-z0-9]{8,20}$/.test(str) ){
        return true;
    } else {
        return false;
    }
}
/*
 * 身份证号合法性验证 
 * 支持15位和18位身份证号
 * 支持地址编码、出生日期、校验位验证
 */
//function IdentityCodeValid(code) { 
//	code=code.toUpperCase();
//    var city={11:"北京",12:"天津",13:"河北",14:"山西",15:"内蒙古",21:"辽宁",22:"吉林",23:"黑龙江 ",31:"上海",32:"江苏",33:"浙江",34:"安徽",35:"福建",36:"江西",37:"山东",41:"河南",42:"湖北 ",43:"湖南",44:"广东",45:"广西",46:"海南",50:"重庆",51:"四川",52:"贵州",53:"云南",54:"西藏 ",61:"陕西",62:"甘肃",63:"青海",64:"宁夏",65:"新疆",71:"台湾",81:"香港",82:"澳门",91:"国外 "};
//    var tip = "";
//    var pass= true;
//    if(!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[12])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)){
//        tip = "身份证号格式错误";
//        pass = false;
//    }else if(!city[code.substr(0,2)]){
//        tip = "地址编码错误";
//        pass = false;
//    }else{
//        //18位身份证需要验证最后一位校验位
//        if(code.length == 18){
//            code = code.split('');
//            //∑(ai×Wi)(mod 11)
//            //加权因子
//            var factor = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ];
//            //校验位
//            var parity = [ 1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2 ];
//            var sum = 0;
//            var ai = 0;
//            var wi = 0;
//            for (var i = 0; i < 17; i++)
//            {
//                ai = code[i];
//                wi = factor[i];
//                sum += ai * wi;
//            }
//            var last = parity[sum % 11];
//            if(parity[sum % 11] != code[17]){
//                tip = "校验位错误";
//                pass =false;
//            }
//        }
//    }
//    tip='请输入正确身份证号';
//    if(!pass) alert(tip);
//    return pass;
//}

/*
 * 身份证号校验
 * 
 * */
function IdentityCodeValid(fieldId,tip){
	var sId=$('#'+fieldId).val();
	
	sId = certNoTrans18(sId);
	
	sId=sId.toUpperCase();
	var pass=true;
	var aCity={11:"北京",12:"天津",13:"河北",14:"山西",15:"内蒙古",21:"辽宁",22:"吉林",23:"黑龙江",31:"上海",32:"江苏",33:"浙江",34:"安徽",35:"福建",36:"江西",37:"山东",41:"河南",42:"湖北",43:"湖南",44:"广东",45:"广西",46:"海南",50:"重庆",51:"四川",52:"贵州",53:"云南",54:"西藏",61:"陕西",62:"甘肃",63:"青海",64:"宁夏",65:"新疆",71:"台湾",81:"香港",82:"澳门",91:"国外"} 
	var iSum=0 ;
	var info="" ;
	if(!/^\d{17}(\d|x)$/i.test(sId)){
		pass=false;
//		return "你输入的身份证长度或格式错误";
	} 
	sId=sId.replace(/x$/i,"a");
	if(aCity[parseInt(sId.substr(0,2))]==null){
		pass=false;
//		return "你的身份证地区非法";
	} 
	sBirthday=sId.substr(6,4)+"-"+Number(sId.substr(10,2))+"-"+Number(sId.substr(12,2));
	var d=new Date(sBirthday.replace(/-/g,"/")) ;
	if(sBirthday!=(d.getFullYear()+"-"+ (d.getMonth()+1) + "-" + d.getDate())){
		pass=false;
//		return "身份证上的出生日期非法";
	}
	for(var i = 17;i>=0;i --) iSum += (Math.pow(2,i) % 11) * parseInt(sId.charAt(17 - i),11) ;
	if(iSum%11!=1){
		pass=false;
//		return "你输入的身份证号非法";
	}
//  	aCity[parseInt(sId.substr(0,2))]+","+sBirthday+","+(sId.substr(16,1)%2?"男":"女");//此次还可以判断出输入的身份证号的人性别
	if(isNull(tip))
		tip='请输入正确身份证号';
    if(!pass) {errShow(fieldId,tip);}
    	
    ;
    return pass;
}


/*
 * 身份证号转换15位到18位
 * 
 * */
function certNoTrans18(certNo){
	if(/^\d{15}$/i.test(certNo)){ //验证是否是15位		    
    	var num = certNo;//身份证号码
        var arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);   
        var arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');   
        var nTemp = 0;     
        num = num.substr(0,6)+'19'+num.substr(6,num.length-6);   
        for(var i = 0; i < 17; i++ )   
        {   
        	nTemp += num.substr(i, 1) * arrInt[i];   
        }   
        num = num + '' +arrCh[nTemp % 11];   
        return num;
    }else{
    	return certNo;
    }
}


/*
 * 年龄范围校验
 * 验证规则：必须超过18岁。
 * */
function Age(sn){
	if(!sn){
		return false;
	}
	var bstr = sn.substring(6,14);
	var _birth_year = bstr.substring(0,4);
	var _birth_month = bstr.substring(4,6);
	_birth_month = _birth_month.startsWith('0')?_birth_month.substring(1,2):_birth_month;
	var _birth_date = bstr.substring(6,8);
	_birth_date = _birth_date.startsWith('0')?_birth_date.substring(1,2):_birth_date;
	
	var _now = new Date();
	var _now_year = _now.getFullYear();
	var _now_month = _now.getMonth()+ 1;
	var _now_date = _now.getDate();
	
	if(Number(_now_year) - Number(_birth_year) > 18){
		return true;
	}else if(Number(_now_year) - Number(_birth_year) == 18 && Number(_now_month) - Number(_birth_month) > 0){
		return true;
	}else if(Number(_now_year) - Number(_birth_year) == 18 && Number(_now_month) - Number(_birth_month) == 0 && Number(_now_date) - Number(_birth_date) >= 0){
		return true;
	}else{
		return false;
	}
}



